<template>
  <div class="policy-wrapper">
    <div class="policy-title">Політика конфіденційності</div>

    <p>
      Згода на збір та обробку персональних даних та повідомлення про права згідно із
      Законом України «Про захист персональних даних» від 01.06.2010 р. № 2297-VI
    </p>
    <p>
      Клієнт (далі – користувач) зобов’язується надавати проекту «BibleAwr» (далі –
      власник) правдиву, точну та повну інформацію про себе з питань, запропонованих у
      реєстраційній формі, та підтримувати цю інформацію в актуальному стані, необхідної.
      Якщо користувач надає неправильну інформацію або власник має серйозні підстави
      вважати, що інформація, надана Клієнтом, є неправильною, неповною або неточною,
      Власник має право призупинити або скасувати реєстрацію або Повністю відмовити
      користувачеві в подальшому розміщенні сайту. замовлення.
    </p>
    <p>
      Користувач підтверджує, що, діючи за своїм бажанням та в своїх інтересах, дає згоду
      на обробку його персональних даних, наданих власником, з перевагами для їх
      ідентифікації.
    </p>
    <p>
      Персональні дані користувача, надані власником при їх реєстрації відповідно до
      чинного законодавства про захист персональних даних, не підлягають передачі третім
      особам без письмового дозволу користувача, за винятком випадків, передбачених чинним
      законодавством. законодавством, або на підставі рішень суду країни перебування
      користувача.
    </p>
    <p><b>Власник використовує лише особисту інформацію:</b></p>
    <p>
      зареєструвати користувача на сайті;<br />виконати свої зобов'язання перед
      користувачем та його ідентифікацію;<br />оцінити та проаналізувати сайт;<br />визначити
      можливість участі користувача в акціях , власником якого є.
    </p>
    <p>
      Власник може надіслати повідомлення користувачеві. Рекламно-інформаційного
      характеру.
    </p>
    <p>
      Відзначаючи пункт на сторінці реєстрації цього веб-сайту «Я погоджуюсь зі збором та
      використанням моїх персональних даних», користувач підтверджує, що отримав
      сповіщення та погоджується з наступним:
    </p>
    <p>
      При використанні Інтернет-сайту власник збирає та обробляє персональні дані
      користувача, пов'язані з ідентифікацією, аутентифікацією, авторизацією, відновленням
      пароля, надсиланням інформаційних матеріалів про підписку користувачеві, відповідей
      на запити та листи користувача, залишаючи відгуки про власника. отримані послуги,
      щодо реєстрації, отримання акційних та спеціальних пропозицій, інформації про акції,
      іншої інформації про діяльність власника, серед яких можуть бути такі персональні
      дані:
    </p>
    <p>прізвище, ім’я, по батькові, телефон, адреса електронної пошти;</p>
    <p>
      Особисті дані, надані користувачем при заповненні реєстраційних форм на веб-сайті, а
      також у процесі використання веб-сайту:
    </p>
    <p><b>Файли cookies.</b></p>
    <p>
      Під обробкою персональних даних розуміється збір, реєстрація, накопичення,
      зберігання, адаптація, уточнення (оновлення, зміна), використання, поширення,
      (включаючи передачу Третім особам), захист, знищення та інші дії (операції) з
      персональними даними .
    </p>
    <p>
      Користувач зобов’язаний змінити свої персональні дані. Якнайшвидше надайте власнику
      інформацію.
    </p>
    <p>
      Власник включає дані користувача до бази персональних даних з моментом, коли
      користувач вперше надає (надсилає) власника персональних даних, заповнивши
      реєстраційну форму на веб-сайті власника.
    </p>
    <p>
      Власник використовує знеособлені дані для націлювання реклами та/або інформаційних
      матеріалів за віком, статтю, іншими даними; для статистичних досліджень; іншими
      способами.
    </p>
    <p>
      Власник бази не передає персональні дані третім особам. без попередньої згоди
      користувача. Власник бази даних не отримує згоди суб’єкта персональних даних про
      передачу ним персональних даних у випадках, передбачених чинним законодавством
      України.
    </p>
    <p>
      Під час використання користувачем веб-сайт може Існують коди ресурсів третьої
      сторони в результаті чого такі треті сторони отримують дані, зазначені в підпунктах
      7.2.3. - 7.2.5 пункту 7.2. Ця згода. Такими інтернет-ресурсами третіх сторін є:
    </p>
    <p>
      Системи збору статистики відвідувань веб-сайту (наприклад, Google Analytics та
      інші);
    </p>
    <p><b>Інші ресурси;</b></p>
    <p>
      Користувач має всі права на захист своїх персональних даних, передбачені чинним
      законодавством України, зокрема, Законом України «Про захист персональних даних», а
      саме:
    </p>
    <p>
      знати про місцезнаходження бази даних, яка містить його персональні дані, її
      призначення та ім’я, місцезнаходження її власника чи розпорядника;
    </p>
    <p>
      отримувати інформацію про умови надання доступу до Персональних даних, зокрема,
      інформацію про третіх осіб, яким передаються його персональні дані, що містяться в
      базі персональних даних;
    </p>
    <p>
      отримувати не пізніше 30 календарних днів з дня отримання запиту, крім випадків,
      передбачених законодавством, інформацію про те, чи зберігаються його персональні
      дані у відповідній базі персональних даних, а також отримувати вміст його
      персональних даних, які зберігаються.
    </p>
    <p>
      здійснювати мотивовану зміну або знищення своїх персональних даних будь-яким
      власником та розпорядником цієї бази, якщо ці дані обробляються незаконно або
      недостовірні;
    </p>
    <p>
      для захисту ваших персональних даних від незаконної обробки та випадкової втрати,
      знищення, пошкодження внаслідок навмисного приховування, ненадання або несвоєчасного
      надання, а також для захисту від надання інформації, яка є недостовірною або
      суперечить гідності та бізнесу репутація особи;
    </p>
    <p>
      звернутись із захисту своїх прав на персональні дані в органи державної влади,
      органи місцевого самоврядування, до повноважень яких відноситься здійснення захисту
      персональних даних;
    </p>
    <p>
      Застосувати засоби правового захисту у разі порушення законодавства про захист
      персональних даних.
    </p>
    <p>про захист від автоматизованого рішення, яке має для нього юридичні наслідки.</p>
    <p>
      Власник приймає всі передбачені законодавством заходи щодо захисту персональних
      даних користувача, зокрема обробка даних здійснюється на обладнанні, що знаходиться
      в захищеному приміщенні з обмеженим доступом.
    </p>
    <p>
      Вводячи свої персональні дані на сторінку реєстрації та натискаючи
      «Зареєструватися», користувач також підтверджує, що Повідомлений про включення
      інформації про нього до бази персональних даних З вищезазначеною метою, про його
      право, визначене Законом України «Про захист персональних даних», а також про осіб,
      які мають передати для виконання вищевказаної мети.
    </p>
  </div>
</template>
<style scoped>
.policy-wrapper {
  max-width: 1218px;
  padding: 20px 15px;
  margin: 0 auto;
  box-sizing: border-box;
}
.policy-title {
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  padding: 40px;
}
.policy-wrapper p {
  padding: 10px;
  font-size: 20px;
  line-height: 30px;
}

</style>
